<template>
  <b-form-group
    label="İçerik"
    label-for="content"
  >
    <b-form-textarea
      id="content"
      v-model="dataItem.content"
      placeholder="Giriniz"
    />
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'SaleDiscoverContent',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['marketingMobileAppSaleDiscoverLines/getItemData']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped></style>
