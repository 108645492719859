<template>
  <b-form-group
    label="Başlık"
    label-for="title"
  >
    <b-form-input
      id="title"
      v-model="dataItem.title"
      placeholder="Giriniz"
    />

  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'SaleDiscoverTitle',
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['marketingMobileAppSaleDiscoverLines/getItemData']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped></style>
