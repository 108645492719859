<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <sale-discover-title />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <sale-discover-content />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import SaleDiscoverTitle from '@/views/Marketing/Mobile_app/Sale_discover/Elements/Lines/titleCard.vue'
import SaleDiscoverContent from '@/views/Marketing/Mobile_app/Sale_discover/Elements/Lines/contentCard.vue'

export default {
  name: 'SaleDiscoverLineItemForm',
  components: {
    SaleDiscoverContent,
    SaleDiscoverTitle,
    BRow,
    BCol,
  },
}
</script>
