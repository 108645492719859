<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card title="Alt Öğe Düzenle">
        <sale-discover-item-form />
      </b-card>
      <sale-discover-image-card />
      <b-button
        :disabled="dataItem.submitStatus"
        variant="primary"
        @click="submitData"
      >
        <feather-icon icon="SaveIcon" />
        Kaydet
      </b-button>
    </validation-observer>
  </div>
</template>
<script>
import { BCard, BButton } from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import SaleDiscoverItemForm from '@/views/Marketing/Mobile_app/Sale_discover/itemLineForm.vue'
import SaleDiscoverImageCard from '@/views/Marketing/Mobile_app/Sale_discover/Elements/Lines/Image.vue'

export default {
  name: 'Edit',
  components: {
    SaleDiscoverImageCard,
    SaleDiscoverItemForm,
    BCard,
    ValidationObserver,
    BButton,
  },
  computed: {
    dataItem() {
      return this.$store.getters['marketingMobileAppSaleDiscoverLines/getItemData']
    },
    saveData() {
      return this.$store.getters['marketingMobileAppSaleDiscoverLines/getItemDataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    localize('tr')
    this.getData()
    this.$store.commit('marketingMobileAppSaleDiscoverLines/RESET_ITEM_DATA')
  },
  methods: {
    getData() {
      this.$store.dispatch('marketingMobileAppSaleDiscoverLines/itemData', this.$route.params.id)
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('marketingMobileAppSaleDiscoverLines/saveData', this.dataItem)
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
